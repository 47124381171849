import React from "react"
import Layout from "../components/layout";
import { Container } from "@material-ui/core";
import styled from "@emotion/styled";
import { PageHeader } from "../components/PageHeader";
import { FadingImages } from "../components/FadingImages";
import image1 from "../images/who/who1.png";
import image2 from "../images/who/who2.png";
import image3 from "../images/who/who3.png";
import image4 from "../images/who/who4.png";
import image5 from "../images/who/who5.png";

const AuthorImageWrapper = styled.div`
  text-align: center;
  margin: 2rem 0;
`;

const WhoPage = () => {

  const images = [image1, image2, image3, image4, image5];

  return (
    <Layout>
      <Container maxWidth="md">

        <PageHeader>
          Who
        </PageHeader>

        <AuthorImageWrapper>
          <FadingImages
            images={images}
            width={300}
            height={400}
          />
        </AuthorImageWrapper>

        <p>
          Jack Sleepwalker, geboren 1978, stammt aus einer multinationalen Familie. Er ist gebürtig im streng
          konservativen und römisch-katholisch geprägten Polen, wuchs jedoch nach seinem 10. Lebensjahr in
          Deutschland auf, wo er Diplom Sozialwissenschaften studierte und sich mit seiner Arbeit für diverse
          soziale und queere Institutionen engagiert. Seine Werke werden beeinflusst durch seine eigenen Erfahrungen
          als nicht-binäre, bisexulle, transgeschlechtliche, polygam veranlagte Person.
        </p>

        <p>
          Er trat in Deutschland aus der katholischen Kirche aus und nahm paganen Glauben an.
        </p>

        <p>
          Sleepwalker lebt mit seinem Mann und seiner Familie zurückgezogen im Ruhrgebiet,
          wo er als Autor und Künstler freischaffend arbeitet.
        </p>

      </Container>
    </Layout>
  );

};

export default WhoPage
